import { FC } from "react"

import { TRaidStatus } from "@/api/raid"
import { calculateTimeRemaining } from "@/lib/calculateTimeRemaining"

type StatusBadgeProps = {
  status: TRaidStatus | string
  startDate: number
}

export const HackathonStatusBadge: FC<StatusBadgeProps> = ({
  status,
  startDate,
}) => {
  let bgColor = ""
  let displayText = status

  switch (status) {
    case "終了":
      bgColor = "bg-[#373A40]"
      break
    case "開催予定":
      bgColor = "bg-[#3C8AF5]"
      if (startDate !== null) {
        const timeRemaining = calculateTimeRemaining(startDate)
        if (timeRemaining) {
          displayText = `締切まで残り ${timeRemaining.value}${timeRemaining.unit}`
        }
      }
      break
    case "開催中":
      bgColor = "bg-[#B83CF5]"
      break
    default:
      bgColor = "bg-gray-100"
  }

  return (
    <div className={`flex h-6 items-center rounded-3xl ${bgColor}`}>
      <span className="px-3 text-xs text-white">{displayText}</span>
    </div>
  )
}
