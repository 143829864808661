import { Card, CardBody, CardFooter } from "@nextui-org/card"
import { Image } from "@nextui-org/image"
import NextLink from "next/link"
import { FC } from "react"

import { IconTextItem } from "../[hackathonId]/_components/IconTextItem"
import { OrganizerInfo } from "../[hackathonId]/_components/OrganizerInfo"

import { TOrganizer } from "@/api/organizer"
import { TRaidSummary } from "@/api/raid"
import { HackathonStatusBadge } from "@/components/Hackathon/HackathonStatusBadge"
import { HackathonTag } from "@/components/Hackathon/Tag"
import {
  formatHackathonCardDate,
  formatHackathonDetailDate,
} from "@/lib/formatHackathonDate"

type Props = {
  hackathon: TRaidSummary
  organizers?: TOrganizer[]
}

export const HackathonCard: FC<Props> = ({ hackathon, organizers }) => {
  return (
    <NextLink href={`/hackathon/${hackathon.info.id}`}>
      <Card
        className="text-left hover:cursor-pointer sm:w-[360px]"
        isBlurred
        shadow="none"
        isPressable
      >
        <CardBody className="p-0">
          <div className="relative">
            <Image
              src={hackathon.info.imageUrl}
              alt={hackathon.info.title}
              className="aspect-[16/9] w-full object-cover"
            />
            <div className="absolute inset-0 z-40">
              <div className="absolute right-0 top-0 m-2">
                <HackathonStatusBadge
                  status={hackathon.info.status}
                  startDate={hackathon.info.eventDate.raidStart}
                />
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter className="flex flex-col items-start gap-2">
          <h3 className="text-lg font-bold">{hackathon.info.title}</h3>

          {/* 場所 / 日付 */}
          <div className="flex w-full justify-between gap-2">
            <IconTextItem
              iconInfo={{ name: "RiMapPinRangeFill", isRiIcon: true }}
              text={hackathon.info.venueInfo.venueArea}
            />
            <IconTextItem
              iconInfo={{ name: "", isRiIcon: false }}
              text={`${formatHackathonDetailDate(hackathon.info.eventDate.raidStart)}〜
                ${formatHackathonCardDate(hackathon.info.eventDate.raidEnd)}`}
            />
          </div>

          {/* 賞金 / 参加者 */}
          <div className="flex w-full justify-between gap-2">
            <IconTextItem
              iconInfo={{ name: "RiTrophyFill", isRiIcon: true }}
              text={
                hackathon.info.prize.total === "" ||
                hackathon.info.prize.total == null
                  ? "0円"
                  : hackathon.info.prize.total
              }
              description="賞金総額"
              direction="row"
            />
            <IconTextItem
              iconInfo={{ name: "RiTeamFill", isRiIcon: true }}
              text={
                hackathon.info.playerLimit === -1
                  ? "∞"
                  : hackathon.info.playerLimit
              }
              description="参加上限"
              direction="row"
            />
          </div>
          <div className="flex flex-wrap gap-2">
            {hackathon.tags.map((tag) => (
              <HackathonTag value={tag.value} key={tag.id} />
            ))}
          </div>
          <IconTextItem
            iconInfo={{ name: "RiTeamFill", isRiIcon: true }}
            text={
              hackathon.info.playerLimit === -1
                ? "∞"
                : hackathon.info.playerLimit
            }
          />
          {organizers && (
            <OrganizerInfo organizers={organizers} containSnsIcons={false} />
          )}
        </CardFooter>
      </Card>
    </NextLink>
  )
}
