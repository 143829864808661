type Props = {
  value: string
  disabled?: boolean
}

export const HackathonTag = ({ value, disabled = false }: Props) => {
  return (
    <div className="flex h-6 items-center rounded-3xl bg-[#E9D7FE]">
      <span
        className={`px-3 text-xs font-bold ${disabled ? "text-gray-600 opacity-50" : "text-black"}`}
      >
        {value}
      </span>
    </div>
  )
}
