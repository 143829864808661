import { toZonedTime } from "date-fns-tz"

const timeZone = "Asia/Tokyo"
const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"]

/**
 * 2桁の数値文字列にフォーマットする
 * @param num 数値
 * @returns 2桁の文字列（例: "01", "12"）
 */
const _format2Digits = (num: number): string => {
  return `${num.toString().padStart(2, "0")}`
}

/**
 * 日付を "MM/DD" 形式の文字列に変換する
 * @param date Dateオブジェクト
 * @returns "MM月DD日" 形式の文字列（例: "12月25日"）
 */
const _getDate = (date: Date): string => {
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${_format2Digits(month)}月${_format2Digits(day)}日`
}

/**
 * 時刻を "HH:mm" 形式の文字列に変換する
 * @param date Dateオブジェクト
 * @returns "HH:mm" 形式の文字列（例: "14:30"）
 */
const _getTime = (date: Date): string => {
  const hour = date.getHours()
  const minutes = date.getMinutes()

  return `${_format2Digits(hour)}:${_format2Digits(minutes)}`
}

/**
 * UnixタイムスタンプからJST(日本時間)のDateオブジェクトを取得する
 * @param unixTimestamp Unixタイムスタンプ（秒）
 * @returns JST(日本時間)のDateオブジェクト
 */
const _toJpDate = (unixTimestamp: number): Date => {
  const utcDate = new Date(unixTimestamp * 1000)
  return toZonedTime(utcDate, timeZone)
}

/**
 * UnixタイムスタンプをMM/DD(曜)HH:mm形式の日本時間文字列に変換する
 * @param unixTimestamp Unixタイムスタンプ（秒）
 * @returns "MM/DD(曜)HH:mm" 形式の文字列（例: "12/25(月)14:30"）
 */
export const formatHackathonDate = (unixTimestamp: number): string => {
  const jpDate = _toJpDate(unixTimestamp)
  const dayOfWeekIndex = jpDate.getDay()
  return `${_getDate(jpDate)}(${dayOfWeek[dayOfWeekIndex]})${_getTime(jpDate)}`
}

/**
 * UnixタイムスタンプをMM/DD形式の日本時間文字列に変換する
 * @param unixTimestamp Unixタイムスタンプ（秒）
 * @returns "MM月DD日" 形式の文字列（例: "12月25日"）
 */
export const formatHackathonCardDate = (unixTimestamp: number): string => {
  return _getDate(_toJpDate(unixTimestamp))
}

/**
 * UnixタイムスタンプをYYYY年MM月DD日形式の日本時間文字列に変換する
 * @param unixTimestamp Unixタイムスタンプ（秒）
 * @returns "YYYY年MM月DD日" 形式の文字列（例: "2025年02月09日"）
 */
export const formatHackathonDetailDate = (unixTimestamp: number): string => {
  const jpDate = _toJpDate(unixTimestamp)
  const year = jpDate.getFullYear()
  return `${year}年${_getDate(jpDate)}`
}
