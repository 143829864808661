/**
 * 残り日数を計算
 * @param targetDate 数値
 * @returns 残り時間・日数（例： 23日）
 */

export const calculateTimeRemaining = (
  targetDate: number,
): { value: number; unit: "日" | "時間" } | null => {
  const now = new Date()
  const target = new Date(targetDate * 1000)
  const diff = target.getTime() - now.getTime()

  if (diff < 0) return null

  // 1日以上ある場合は日数で表示
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  if (days > 0) {
    return { value: days, unit: "日" }
  }

  // 1日未満の場合は時間で表示
  const hours = Math.max(1, Math.floor(diff / (1000 * 60 * 60)))
  return { value: hours, unit: "時間" }
}
